import { useState, useEffect } from "react";
import { ethers, utils } from "ethers";
import "./App.css";
import {
  BLASTSTAKINGABI,
  BLASTSTAKINGCONTRACTADDRESS,
  BLASTTOKENCONTRACTADDRESS,
  BLASTTOKENABI,
} from "./constant.js";
import ConnectWallet from "./Component/connectWallet";
import Staking from "./Component/staking";

function App() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState("");
  const [tierAndeligiblity, setTierAndeligiblity] = useState();

  async function connect() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    setAccount(signer);
    setProvider(provider);
  }

  async function getStakeDetails() {
    const blastStakingContract = new ethers.Contract(
      BLASTSTAKINGCONTRACTADDRESS,
      BLASTSTAKINGABI.abi,
      provider
    );
    const blastStakingContractWithSigner = await blastStakingContract.connect(
      account
    );

    const walletAddress = await account?.getAddress();
    const stakeDetails = await blastStakingContractWithSigner.Stake(
      walletAddress
    );
    return stakeDetails;
  }

  const getTierAndEligiblity = async () => {
    try {
      const blastStakingContract = new ethers.Contract(
        BLASTSTAKINGCONTRACTADDRESS,
        BLASTSTAKINGABI.abi,
        provider
      );
      const blastStakingContractWithSigner = await blastStakingContract.connect(
        account
      );

      const walletAddress = await account?.getAddress();
      const response =
        await blastStakingContractWithSigner.getTierAndEligibility(
          walletAddress
        );
      setTierAndeligiblity(response);
      return response;
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  async function unStakeTokens(amount) {
    if (!provider) {
      return alert("Wallet Not Connected");
    }
    const blastStakingContract = new ethers.Contract(
      BLASTSTAKINGCONTRACTADDRESS,
      BLASTSTAKINGABI.abi,
      provider
    );
    try {
      const blastStakingContractWithSigner =
        blastStakingContract.connect(account);

      const response = await blastStakingContractWithSigner.unstakeTokens(
        amount
      );
      console.log("response", response);
    } catch (error) {
      console.log("ERROR:", error.reason);
    }
  }

  async function stakeTokens(amount) {
    if (!provider) {
      return alert("Wallet Not Connected");
    }
    const blastTokenContract = new ethers.Contract(
      BLASTTOKENCONTRACTADDRESS,
      BLASTTOKENABI.abi,
      provider
    );
    const blastStakingContract = new ethers.Contract(
      BLASTSTAKINGCONTRACTADDRESS,
      BLASTSTAKINGABI.abi,
      provider
    );
    try {
      const blastTokenContractWithSigner = blastTokenContract.connect(account);
      const blastStakingContractWithSigner =
        blastStakingContract.connect(account);

      const approvalTx = await blastTokenContractWithSigner.approve(
        BLASTSTAKINGCONTRACTADDRESS,
        amount
      );
      await approvalTx.wait();
      const response = await blastStakingContractWithSigner.stakeTokens(amount);
    } catch (error) {
      console.log("ERROR:", error.message);
    }
  }

  return (
    <>
      {!provider ? (
        <ConnectWallet connect={connect} />
      ) : (
        <Staking
          stakeTokens={stakeTokens}
          unstakeTokens={unStakeTokens}
          getTierAndEligibility={getTierAndEligiblity}
          getStakeDetails={getStakeDetails}
          address={account}
        />
      )}
    </>
  );
}

export default App;
